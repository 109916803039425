var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.edit)?_c('ValidationProvider',{attrs:{"vid":_vm.vid,"name":_vm.name,"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validated = ref.validated;
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',_vm._b({attrs:{"horizontal":_vm.vertical === false,"label-cols":_vm.vertical === false ? 3 : null,"label-breakpoint":"md","label-for":_vm.uuid,"invalid-feedback":errors[0],"label":_vm.label}},'b-form-group',_vm.$attrs,false),[_c('v-date-picker',{class:{
          'is-invalid': validated && !valid,
          'is-valid': validated && valid,
        },attrs:{"type":_vm.type,"show-week-number":_vm.broadcastCalendar !== false,"show-second":false,"clearable":_vm.clearable,"disabled-date":_vm.notBeforeMinDate,"show-time-panel":_vm.showTimePanel,"range":_vm.range,"confirm":true,"minute-step":5,"use12h":true,"shortcuts":_vm.shortcut_options,"disabled":_vm.disabled,"lang":_vm.lang},on:{"close":_vm.handleClose},scopedSlots:_vm._u([(_vm.type === 'datetime')?{key:"footer",fn:function(){return [_c('IconAction',{attrs:{"title":"Edit Time","icon":_vm.showTimePanel ? 'calendar' : 'clock'},on:{"click":_vm.toggleTimePanel}})]},proxy:true}:null],null,true),model:{value:(_vm.local_value),callback:function ($$v) {_vm.local_value=$$v},expression:"local_value"}})],1)]}}],null,false,4207289475)}):_c('b-form-group',{staticClass:"col-form-label",attrs:{"horizontal":_vm.vertical === false,"label-cols":_vm.vertical === false ? 3 : null,"label":_vm.label,"label-breakpoint":"md"}},[_c('span',[_vm._v(_vm._s(_vm._f("moment")(_vm.local_value,"MMMM Do, YYYY")))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }